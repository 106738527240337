@media (prefers-reduced-motion: no-preference) {
    .sliding-insight {
      animation: sliding-insight-animation 1s ease-in-out;
      animation-fill-mode: both;
    }
}
  
  
@keyframes sliding-insight-animation {
    0% {
      margin-top: -140px;
    }
    80% {
        margin-top: 15px;
    }
    100% {
      margin-top: 0px;
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .sliding-banner {
      animation: sliding-banner-animation 0.30s ease-out alternate;     
      animation-fill-mode: both;
    }
}
  
  
@keyframes sliding-banner-animation {
    0% {
      margin-top: 20px;
    }
    100% {
      margin-top: -25px;
    }
  }