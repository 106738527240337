@media (prefers-reduced-motion: no-preference) {
    .refresh-upcoming-meetings {
      animation: refresh-upcoming-meeting-animation infinite 1.2s linear;
      animation-fill-mode: both;
    }
}
  
  
@keyframes refresh-upcoming-meeting-animation {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }